import React from 'react';
import Images from '../Images/manage_level.PNG';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function ManageLevel() {
  return (
    <>
    <Watermark/>

    <section className='about' id="about">
        <title>Manage Level</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Manage Level</h2>
            <p>
                The ultimate app to raise, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
        <img src={Images} className="imgs-left" alt="Manage Level" title="Manage Level"/>
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Manage Level:</h2>
        <h4>Add Escalation Level:</h4>
        <p>Enter the name of the hierarchy level and the description, if admin wants to notify the user through SMS and Email</p>
        <h4 className='mt-5'>Escalation Level List:</h4>
        <p>Manage-Level displays the list of Names and Code of the Hierarchy level of the Escalation.</p>         
      </div>
    </div>

    </div>

    </div>
    </section>
    </>
  )
}
