import React from 'react';
import '../CSS/styles.css';
import '../CSS/features.css';
import Images from '../Images/dashboard.png';
// import Images0 from '../Images/raise.png';
import Images1 from '../Images/search.png';
import Images2 from '../Images/track.png';
import Images3 from '../Images/close.png';
// import Images4 from '../Images/technician.png';
// import Images5 from '../Images/escalation.png';
// import Images6 from '../Images/design.png';
// import Images7 from '../Images/notifications.png';
import { Link } from 'react-router-dom';

export default function Technician() {
  return (
    <>
    <section className="features mt-3" id="features">
    <div className="container">
    <title>Technician Help</title>
        <div className="title">
            <h2>Technician</h2>
            <p>Discover all the useful features related to technician<br/>
                Every single piece of information is at your fingertip.
            </p>
        </div>
        <div className="row justify-content-center mar">
            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images} className="img-fluid" alt="Dashboard" title="Dashboard"/>
                <div className="card-body nav-items mt-5">
                    <Link className="nav-links" to="/TechnicianDashboard" style={{fontWeight:"800"}}>Dashboard</Link>
                        {/* <h3>Dashboard</h3> */}
                        <p className='mt-3'>From dashboard technician can check active complaints. And also can see the counts of various statuses.</p>
                </div>
                </div>
            </div>
           
            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images1} className="img-fluid" alt="Complaint" title="Complaint"/>
                    <div className="card-body nav-items mt-5">
                        {/* <h3>Complaint</h3> */}
                            <Link className="nav-links" to="/SearchComplaint" style={{fontWeight:"800"}}>Search Complaint</Link>
                        <p className='mt-3'>Technician can search the complaint to check the number complaints assigned to them.</p>
                    </div>
                </div>
            </div>

            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images2} className="img-fluid" alt="Complaint" title="Complaint"/>
                    <div className="card-body nav-items mt-5">
                        {/* <h3>Complaint</h3> */}
                            <Link className="nav-links" to="/TrackComplaint" style={{fontWeight:"800"}}>Track Complaint</Link>
                        <p className='mt-3'>Technician can track the complaint assigned to them to check the status of the complaint.</p>
                    </div>
                </div>
            </div>

            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images3} className="img-fluid" alt="IVR" title="IVR"/>
                    <div className="card-body nav-items mt-5">
                        <Link className="nav-links" to='/Close' style={{fontWeight:"800"}}>Close Complaint</Link>
                        {/* <h3>IVR</h3> */}
                        <p className='mt-3'>Technician will close the complaint after resolving it successfully by uploading the jobcard.</p>
                    </div>
                </div>
            </div>
            
            <div className='mb-5'></div>
        </div>
    </div>
    </section>
    </>
  )
}
