import React from 'react';
import Images from '../Images/complainant-icon.png';
import Images1 from '../Images/technicianicon.png';
import Images2 from '../Images/admin.png';
import { Link } from 'react-router-dom';
import '../CSS/styles.css';
import '../CSS/download.css';

export default function Help() {
  return (
    <section className="mt-5" id="features">
    <div className="container">
    <title>Help</title>
        <div className="title">
            <h2>How to Use</h2>
            <p>Explore all the features of every user role of CMS to know more in detail.
            </p>
        </div>
        <div className='row download'>
        <div className="col mt-5">
                <div className="row app-detail">
                    <div className="col  text-center">
                      <div className="user-info">
                      <Link className="nav-links" to='/Complainant' style={{fontWeight:"800"}}>
                            <img src={Images} alt="Complainant" title="Complainant" style={{width:"150px"}}/>
                            <h3 className='mt-3'>Complainant</h3>
                            {/* <p className='mt-3'>Positive Reviews</p> */}
                      </Link>
                      </div>
                    </div>
                    
                    <div className="col  text-center">
                      <div className="user-info">
                      <Link className="nav-links" to='/Technician' style={{fontWeight:"800"}}>
                            <img src={Images1} alt="Technician" title="Technician" style={{width:"150px"}}/>
                            <h3 className='mt-3'>Technician</h3>
                            {/* <p className='mt-3'>Positive Reviews</p> */}
                      </Link>
                      </div>
                    </div>

                    <div className="col  text-center">
                      <div className="user-info">
                      <Link className="nav-links" to='/Admin' style={{fontWeight:"800"}}>
                            <img src={Images2} alt="Admin / Supervisor" title="Admin / Supervisor" style={{width:"150px"}}/>
                            <h3 className='mt-3'>Admin / Supervisor</h3>
                            {/* <p className='mt-3'>Positive Reviews</p> */}
                      </Link>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <div style={{paddingBottom:"18px"}}></div>
      </div>
    </section>
  )
}
