import React from 'react';
import Images from '../Images/design_flow.PNG';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function DesignFlow() {
  return (
    <>
    <Watermark/>

    <section className='about' id="about">
        <title>Escalation Design Flow</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Escalation Design Flow</h2>
            <p>
                The ultimate app to raise, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
        <img src={Images} className="imgs-left" alt="Escalation Design Flow" title="Escalation Design Flow"/>
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Escalation Design Flow:</h2>
        {/* <h4>Add Escalation Level:</h4> */}
        <p>Displays the Hierarchy level of the Open Complaint, and intimate the dedicated person according to the given parameters.</p>
        <p>Admin can create and modify the Hierarchy Level according to the time.</p>
        {/* <h4 className='mt-5'>Escalation Level List:</h4> */}
        <ul>
          <li>Current Escalation Level</li>
          <li>Next Escalation Level</li>
          <li>Report Email Time-1*</li>
          <li>Report Email Time-2 (Optional)</li>
          <li>Complaint Stay Duration</li>
        </ul>
        <p>Report Time means at what to time the reminder or intimation of the status.</p>         
      </div>
    </div>

    </div>
    </div>
    </section>
    </>
  )
}
