import React from 'react';
import '../CSS/reviews.css';
import '../CSS/styles.css';
import Images from '../Images/leads.gif';

export default function Reviews() {
  return (
    <section className="review" id="reviews">
    <div className="container mt-5">
    <title>Reviews</title>
        <div className="title">
            <h2>Process Flow of the Complaints</h2>
            <p>
                Take a sneak peek at the App reviews to know how delighted our users are with our helpful app services.<br/>
                Feedback of our users helps to improve app performance and user experience.
            </p>
        <div className="container">
            <img src={Images} className="img-fluid" alt="Process Flow" style={{borderRadius:"8px"}}/>
        </div>
        </div>
    </div>
    </section>            
  )
}
