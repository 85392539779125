import React from 'react';
import Images from '../Images/department.PNG';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function Department() {
  return (
    <>
    <Watermark/>
    <section className='about' id="about">
        <title>Department</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Department</h2>
            <p>
                The ultimate app to Search, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
          <img src={Images} className="imgs-right" alt="Department" title="Department" style={{float: "right"}}/>
      </div>
      <div className='col-sm-6'>
          <h2 style={{fontWeight:"800"}}>Add Department:</h2>
            <p>Click on the button <span style={{fontWeight:"bold"}}>Add Department</span>
              , admin can add the Department and Description</p>
              <ul>
                <li>Enter Department Name</li>
                <li>Description</li>
              </ul>
      </div>
    </div>
    </div>
    </div>
    </section>
    </>
  )
}
