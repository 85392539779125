import React from 'react';
import Images from '../Images/admin_dashboard.PNG';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function Dashboard() {
  return (
    <>
    <Watermark/>
    <section className='about' id="about">
        <title>Dashboard</title>
    <div className='container mt-3' style={{width:"100vw"}}>
        <div className='title'>
            <h2>Dashboard</h2>
            <p>
                The ultimate app to Search, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
                <img src={Images} alt="Admin Dashboard" title="Dashboard" style={{width: "100%",marginTop:"30px"}}/>                    
        </div>

        {/* <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
                <div className="video-wrapper">
                </div>
            </div>
        </div> */}
    </div>
    </section>
    </>
  )
}
