import React from 'react';
import Images from '../Images/iiml.png';
import '../CSS/styles.css';
import '../CSS/works.css';

export default function Works() {
  return (
    <>
    <section className="how-it-work" id="how-it-works">
    <div className="container mt-5">
    <title>How it Works</title>
        <div className="title">
            <h2>How To Use The Portal</h2>
            <p>
                Have a glance at the overview of the CMS Portal with its excellent features.
            </p>
        </div>
        <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
                <div className="video-wrapper mb-5">
                    <img src={Images} className="img-fluid" alt="How To Use The App" title="How To Use The App"/>
                    {/* <a className="button is-play fancybox" href="https://www.youtube.com/embed/ngH2S3j4WOQ">
                        <div className="button-outer-circle has-scale-animation"></div>
                        <div className="button-outer-circle has-scale-animation has-delay-short"></div>
                        <div className="button-icon is-play">
                            <i className="bx bx-play"></i>
                        </div>
                    </a> */}
                </div>
            </div>
        </div>
    </div>
    </section>
    </>
  )
}
