import React from 'react';
import Images from '../Images/track_complaint.PNG';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function TrackComplaint() {
  return (
    <>
    <Watermark/>

    <section className='about' id="about">
        <title>Track Complaint</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Track Complaint</h2>
            <p>
                The ultimate app to track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
        <img src={Images} className="imgs-left" alt="Track Complaint" title="Track Complaint"/>
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Track Complaint</h2>
        <p>A user can search the status of complaint by one of the given parameters:</p>
        <h4>Track by Ticket Number:</h4>
        <p>By entering the ticket number and clicking on the Track button, a table will display for the entered ticket number</p>
        {/* <h4>Track by Mobile Number</h4> */}
        <p>Tracking by the ticket number, a hierarchy status of complaint will generated.</p>
      </div>
    </div>

    </div>
    </div>
    </section>
    </>
  )
}
