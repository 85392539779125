import React from 'react';
import Images8 from '../Images/iimlogo.png';

export default function Watermark() {
  return (
    <div className="container">        
        <img src={Images8} className="watermark" alt="logo"/>
    </div>
  )
}
