import React from 'react';
import '../CSS/styles.css';
import '../CSS/features.css';
import Images from '../Images/raise.png';
import Images1 from '../Images/search.png';
import Images2 from '../Images/track.png';
import Images3 from '../Images/live.png';
import Images4 from '../Images/technician.png';
import Images5 from '../Images/escalation.png';
import Images6 from '../Images/design.png';
import Images7 from '../Images/notifications.png';

export default function Features() {
  return (
    <>
    <section className="features" id="features">
        <div className="container mt-5">
            <title>
                Features
            </title>
            <div className="title">
                <h2>
                    Best Features
                </h2>
                <p>Discover all the useful details with the most desired features<br/>
                    Every single feature's description is at your fingertip.
                </p>
            </div>

            <div className="row justify-content-center">
                <div className="col-md-4 col-lg-3 col-xl-3">
                    <div className="card">
                        <img src={Images} className="img-fluid" alt="Raise Complaint" title="Raise Complaint"/>
                        <div className="card-body">
                            <h3>Raise Complaints</h3>
                            <p>The complainant can raise complaints through Web, App, and IVR to resolve their complaint.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-lg-3 col-xl-3">
                    <div className="card">
                        <img src={Images1} className="img-fluid" alt="Search Complaint" title="Search Complaint"/>
                        <div className="card-body">
                            <h3>Search Complaints</h3>
                            <p>The complainant can search the complaint raised by them to check the status of the complaint.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-lg-3 col-xl-3">
                    <div className="card">
                        <img src={Images2} className="img-fluid" alt="Track Complaint" title="Track Complaint"/>
                        <div className="card-body">
                            <h3>Track Complaints</h3>
                            <p>The complainant can track the complaint raised by them to check the status of the complaint.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-lg-3 col-xl-3">
                    <div className="card">
                        <img src={Images3} className="img-fluid" alt="Live Feed" title="Live Feed"/>
                        <div className="card-body">
                            <h3>Live Feed</h3>
                            <p>On live feeds, you can check the complaints which are open, and also you can see the details of the complaint.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-lg-3 col-xl-3">
                    <div className="card">
                        <img src={Images4} className="img-fluid" alt="Technician Board" title="Technician Board"/>
                        <div className="card-body">
                            <h3>Technician Board</h3>
                            <p>Technician can search, track and close complaint by accessing to this board.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-lg-3 col-xl-3">
                    <div className="card">
                        <img src={Images5} className="img-fluid" alt="Escalation Matrix" title="Escalation Matrix"/>
                        <div className="card-body">
                            <h3>Escalation Matrix</h3>
                            <p>Escalation Matrix is for escalating the complaint from one level to the next level.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-lg-3 col-xl-3">
                    <div className="card">
                        <img src={Images6} className="img-fluid" alt="Design Flow" title="Design Flow"/>
                        <div className="card-body">
                            <h3>Design Flow</h3>
                            <p>Design Flow is to check the various levels of escalation flow in the portal.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-lg-3 col-xl-3">
                    <div className="card">
                        <img src={Images7} className="img-fluid" alt="Notifications" title="Notifications"/>
                        <div className="card-body">
                            <h3>Notifications</h3>
                            <p>You will be notified for every action taking place on the portal.</p>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
    </section>
    </>
  )
}
