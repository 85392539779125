import React from 'react';
import Images from '../Images/technician_board.PNG';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function TechnicianBoard() {
  return (
    <>
    <Watermark/>

    <section className='about' id="about">
        <title>Technician Board</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Technician Board</h2>
            <p>
                The ultimate app to raise, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
        <img src={Images} className="imgs-left" alt="Technician Board" title="Technician Board"/>
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Technician Board</h2>
        <p>Admin / Supervisor can search the Technician by the help of Category:</p>
        {/* <h4>Track by Ticket Number:</h4> */}
          <ul>
            <li>Electrical</li>
            <li>Plumbing</li>
            <li>Audio Visual</li>
            <li>Telephone</li>
            <li>Lift</li>
            <li>Air Conditioner</li>
            <li>Others</li>
            <li>Welding</li>
          </ul>
      </div>
    </div>

    </div>

    </div>
    </section>
    </>
  )
}
