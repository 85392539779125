import React from 'react';
import '../CSS/styles.css';
import '../CSS/features.css';
import Images from '../Images/dashboard.png';
import Images1 from '../Images/complainticon.png';
import Images2 from '../Images/ivr.png';
import Images3 from '../Images/escalation.png';
import Images4 from '../Images/technician.png';
import Images5 from '../Images/setting.png';
import Images6 from '../Images/reports.png';
import Images7 from '../Images/notifications.png';
import { Link } from 'react-router-dom';

export default function Admin() {
  return (
    <>
    <section className="features mt-5" id="features">
    <div className="container">
    <title>Admin / Supervisor</title>
        <div className="title">
            <h2>Admin / Supervisor</h2>
            <p>Discover all the useful details with the most desired features<br/>
                Every single piece of information is at your fingertip.
            </p>
        </div>
        <div className="row justify-content-center">
            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images} className="img-fluid" alt="Dashboard" title="Dashboard"/>
                <div className="card-body dropdown mt-5">
                    <Link className="nav-links" to='/Dashboard' style={{fontWeight:"800"}}>Dashboard</Link>
                        {/* <h3>Dashboard</h3> */}
                        <p className='mt-3'>On dashboard you can check the complaint details of the open complaint. And also can see the counts of various status.</p>
                </div>
                </div>
            </div>
           
            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images1} className="img-fluid" alt="Complaint" title="Complaint"/>
                    <div className="card-body dropdown mt-5">
                        {/* <h3>Complaint</h3> */}
                        <a className="nav-links dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{fontWeight:"800"}}>
                            Complaint
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-items" to="/RaiseComplaint">Raise Complaint</Link></li>
                            <li><Link className="dropdown-items" to="/SearchComplaint">Search Complaint</Link></li>
                            <li><Link className="dropdown-items" to="/TrackComplaint">Track Complaint</Link></li>
                            <li><Link className="dropdown-items" to="/Complaintoperations">Complaint Operations</Link></li>
                            <li><Link className="dropdown-items" to="/TechnicianBoard">Technician Board</Link></li>
                            <li><Link className="dropdown-items" to="/LiveFeed">Live Feed</Link></li>
                        </ul>
                        <p className='mt-3'>User can Raise, Search, Track, and have access to Complaint operations, Live Feeds, Technician Board.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images2} className="img-fluid" alt="IVR" title="IVR"/>
                    <div className="card-body nav-items mt-5">
                        <Link className="nav-links" to='/IVR' style={{fontWeight:"800"}}>IVR</Link>
                        {/* <h3>IVR</h3> */}
                        <p className='mt-3'>User can raise complaint through IVR by calling on the assigned number given the authorized person.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images3} className="img-fluid" alt="Escalation" title="Escalation"/>
                    <div className="card-body dropdown mt-5">
                        <a className="nav-links dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{fontWeight:"800"}}>
                            Escalation
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-items" to="/Matrix">Matrix</Link></li>
                            <li><Link className="dropdown-items" to="/ManageLevel">Manage Level</Link></li>
                            <li><Link className="dropdown-items" to="/DesignFlow">Design Flow</Link></li>
                        </ul>
                        {/* <h3>Escalation</h3> */}
                        <p className='mt-3'>Escalation matrix is for escalating the complaint from one level to the next level.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images4} className="img-fluid" alt="User Management" title="User Management"/>
                    <div className="card-body dropdown mt-5 dropdown mt-5">
                        <a className="nav-links dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{fontWeight:"800"}}>
                            User Management
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-items" to="/UserManagement">User Management</Link></li>
                            <li><Link className="dropdown-items" to="/AddBulkUser">Add Bulk User</Link></li>
                            <li><Link className="dropdown-items" to="/UserApproval">User Approval</Link></li>
                            <li><Link className="dropdown-items" to="/Roles">Roles & Permission</Link></li>
                        </ul>
                            {/* <h3>User Management</h3> */}
                        <p className='mt-3'>User Management is managed by admin for bulk uploading and user approval and also assigns roles permission.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images5} className="img-fluid" alt="General Setting" title="General Setting"/>
                    <div className="card-body dropdown mt-5">
                        <a className="nav-links dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{fontWeight:"800"}}>
                            General Setting
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-items" to="/Category">Category</Link></li>
                            <li><Link className="dropdown-items" to="/SubCategory">Sub-Category</Link></li>
                            <li><Link className="dropdown-items" to="/Department">Department</Link></li>
                            <li><Link className="dropdown-items" to="/Designation">Designation</Link></li>
                        </ul>
                        {/* <h3>General Setting</h3> */}
                        <p className='mt-3'>General Settings comprises of Category, Sub-Category, Department and Designation.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images7} className="img-fluid" alt="Notifications" title="Notifications"/>
                    <div className="card-body dropdown mt-5">
                        <a className="nav-links dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{fontWeight:"800"}}>
                            Notifications
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-items" to="/SendNotifications">Send Notifications</Link></li>
                            <li><Link className="dropdown-items" to="/ReviewNotifications">Review Notifications</Link></li>
                        </ul>
                        {/* <h3>Notifications</h3> */}
                        <p className='mt-3'>You will be notified for every action taking place on the portal.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images6} className="img-fluid" alt="Report" title="Report"/>
                    <div className="card-body mt-5">
                        <Link className="nav-links" to='/Report' style={{fontWeight:"800"}}>Report</Link>
                        {/* <h3>Report</h3> */}
                        <p className='mt-3'>Admin / Supervisor generate the report of every component - customwise, technician and of escalation matrix.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
    </>
  )
}
