import React from 'react';
import ImagesIVR from '../Images/ivr.png';
import ImagesAndroid from '../Images/android.png';
import ImagesApp from '../Images/appStore.png';
import ImagesWeb from '../Images/webPortal.png';
// import ImagesCall from '../Images/call1.png';
// import ImagesArrow from '../Images/arrow-1.png';
import Images from '../Images/complainants.png';
import Images1 from '../Images/raise.png';
import Images2 from '../Images/search.png';
import Images3 from '../Images/track.png';
import Images4 from '../Images/technicians.png';
import Images5 from '../Images/close.png';
import '../CSS/about.css';
import { Link } from 'react-router-dom';

export default function AboutCMS() {
  return (
    <>
    <section className='about' id="about">
        <title>
            About Campus Management System
        </title>

        <div className='container-fluid mt-3'>
            <div className='title'>
                <h2>About Campus Management System</h2>
                <p>
                    The ultimate app to raise, search and track all the complaints.<br/>
                    With just one click you can access any feature across the app.
                </p>
            </div>

            <div className='row mb-4'>
                <div className='col-sm-3 mb-4 arrow-container'>
                    <div className="about-box boxes">
                        <div className="about-box-inner">
                            <img src={ImagesIVR} className="img-fluid" alt="IVR" title="IVR"/>
                            <Link className="nav-links" to='/IVRNo' style={{fontWeight:"800"}}>
                                Dial to IVR
                            </Link>
                        </div>
                        <p>
                            Complainant can raise complaint through Toll Free Number: <br/>
                        </p>
                        <a href="tel:+91-9109126108" className='arrow'>
                        {/* <img src={ImagesCall} alt="Calling" title="Calling" style={{height:"50px", width:"50px", float:"right"}}/> */}
                        </a>
                    </div>
                </div>

                <div className='col-sm-3 mb-4 arrow-container'>
                    <div className="about-box boxes">
                        <div className="about-box-inner">
                            <img src={ImagesAndroid} alt="Android" title="Android"/>
                            <Link className="nav-links" to='#' style={{fontWeight:"800"}}>
                            {/* <Link className="nav-links" to='/IVRNo' style={{fontWeight:"800"}}> */}
                                Android
                            </Link>
                                {/* <h6>Android</h6> */}
                        </div>
                        <p>
                            Complainant can raise complaint through Android App. <br/>
                        </p>
                        <a target="#" href="https://play.google.com/store/apps/details?id=com.amstech.cms.iimi" role="button" className='arrow'>
                        {/* <a className="app-store-btn btn-google mt-1" target="#" href="https://play.google.com/store/apps/details?id=com.amstech.cms.iimi" role="button"> */}
                            {/* <span className="app-store-btn-subtitle">Get It On</span>
                            <span className="app-store-btn-title">Google Play</span> */}
                        {/* <img src={ImagesArrow} alt="Click Here" title="Click Here" style={{height:"50px", width:"50px", float:"right"}}/> */}
                        </a>
                    </div>
                </div>

                <div className='col-sm-3 mb-4 arrow-container'>
                    <div className="about-box boxes">
                        <div className="about-box-inner">
                            <img src={ImagesApp} alt="App Store" title="App Store"/>
                            <Link className="nav-links" to='#' style={{fontWeight:"800"}}>
                            {/* <Link className="nav-links" to='/IVRNo' style={{fontWeight:"800"}}> */}
                                App Store
                            </Link>
                        </div>
                        <p>
                            The complainant can raise complaints through App Store. <br/>
                        </p>
                        <a target="#" href="https://apps.apple.com/in/app/cms-iim-indore/id1643595535" role="button" className='arrow'>
                        {/* <a className="app-store-btn btn-apple mt-1 ml-2 mr-2" target="#" href="https://apps.apple.com/in/app/cms-iim-indore/id1643595535" role="button" className='arrow'> */}
                            {/* <span className="app-store-btn-subtitle">Available on the</span>
                            <span className="app-store-btn-title">App Store</span> */}
                        {/* <img src={ImagesArrow} alt="Click Here" title="Click Here" style={{height:"50px", width:"50px", float:"right"}}/> */}
                        </a>
                    </div>
                </div>

                <div className='col-sm-3 mb-4 arrow-container'>
                    <div className="about-box boxes">
                        <div className="about-box-inner">
                            <img src={ImagesWeb} alt="Web Portal" title="Web Portal"/>
                                <Link className="nav-links" to='#' style={{fontWeight:"800"}}>Web Portal</Link>
                                {/* <Link className="nav-links" to='/IVRNo' style={{fontWeight:"800"}}>Web Portal</Link> */}
                                {/* <h6>Web Portal</h6> */}
                        </div>
                        <p>
                            Complainant can raise complaint through Web Portal. <br/>
                        </p>
                        <a target="#" href="https://www.cmsiimi.com/app/#/signin-v2" role="button" className='arrow'>
                        {/* <a className="app-store-btn btn-google mt-1" target="#" href="https://play.google.com/store/apps/details?id=com.amstech.cms.iimi" role="button"> */}
                            {/* <span className="app-store-btn-subtitle">Get It On</span>
                            <span className="app-store-btn-title">Google Play</span> */}
                        {/* <img src={ImagesArrow} alt="Click Here" title="Click Here" style={{height:"50px", width:"50px", float:"right"}}/> */}
                        </a>
                        </div>
                </div>
            </div>
        </div>

        <div className='container'>
            <div className='row mt-4' style={{paddingTop:"50px"}}>
                <div className='col-sm-6'>
                    <img src={Images} className="about-img" alt="Complainant Portal" title="Complainant Portal"/>
                </div>
                <div className='col-sm-6 mt-3'>
                    <h3>Complainant Portal</h3>
                    <p>Complainant can raise complaint through Web, App, and IVR. Through this portal complainant can resolve their complaint regarding Air Conditioner, Audio Visual, Civil, Electrical, Lift, Telephone, Welding and other. Complainant can access to following features-</p>
                    <div className='row'>
                    <div className='col-sm-6'>
                    <div className="about-box">
                    <div className="about-box-inner">
                        <img src={Images1} alt="Raise Complaint" title="Raise Complaint"/>
                            <h6>Raise</h6>
                    </div>
                        <p>The complainant can raise complaints through Web, App, and IVR to resolve their complaint.</p>
                    </div>
                    </div>   
                    <div className='col-sm-6'>
                    <div className="about-box">
                    <div className="about-box-inner">
                        <img src={Images2} alt="Search Complaint" title="Search Complaint"/>
                            <h6>Search</h6>
                    </div>
                        <p>The complainant can search the complaint raised by them to check the status of the complaint.</p>
                    </div>   
                    </div>
                    <div className='col-sm-6'>
                    <div className="about-box">
                    <div className="about-box-inner">
                        <img src={Images3} alt="Track Complaint" title="Track Complaint"/>
                            <h6>Track</h6>
                    </div>
                        <p>The complainant can track the complaint raised by them to check the status of the complaint.</p>
                    </div>                       
                    </div>
                </div>
                    </div>
            </div>

            <div className='row mt-3'>
            <div className='col-sm-6 mt-5'>
                <h3>Technician Portal</h3>
                <p>Technician is the one who resolves the complaint assigned by the supervisor and visit to the complainant on assigned date, time and place to solve their issue. Technicians can operate with Web, and App, and also can access to following features-</p>
            <div className='row'>
                <div className='col-sm-6 '>
                <div className="about-box">
                <div className="about-box-inner">
                    <img src={Images2} alt="Search Complaint" title="Search Complaint"/>
                        <h6>Search</h6>
                </div>
                    <p>Technician can search the complaint to check the number complaints assigned to them.</p>
                </div>
                </div>   
                <div className='col-sm-6'>
                <div className="about-box">
                <div className="about-box-inner">
                    <img src={Images3} alt="Track Complaint" title="Track Complaint"/>
                        <h6>Track</h6>
                </div>
                    <p>Technician can track the complaint assigned to them to check the status of the complaint.</p>
                </div>   
                </div>
                <div className='col-sm-6 '>
                <div className="about-box">
                <div className="about-box-inner">
                    <img src={Images5} alt="Close Complaint" title="Close Complaint"/>
                        <h6>Close</h6>
                </div>
                    <p>Once the issue is resolved; technician can close the complaint by complainants concern.</p>
                </div>                       
                </div>
            </div>
            </div>
            <div className='col-sm-6 mt-5 '>
                <img src={Images4} className="about-img" alt="Technician Portal" title="Technician Portal" style={{float: "right"}}/>
            </div>
            </div>
        </div>
        
    </section>
    </>
  )
}
