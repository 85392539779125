import React from 'react';
import Images from '../Images/subcategory.PNG';
import Images1 from '../Images/subcategory1.PNG';
import Separator from '../Help/Separator';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function SubCategory() {
  return (
    <>
    <Watermark/>

    <section className='about' id="about">
        <title>Sub Category</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Sub Category</h2>
            <p>
                The ultimate app to Search, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
        <img src={Images} className="imgs-left" alt="Sub Category" title="Sub Category"/>
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>List of Sub Category:</h2>
        <p>For creating new sub-category, user has to select a category from the selection. After that it displays a new button i.e.  
          <span style={{fontWeight:"bold"}}>Add Sub-Category</span></p>
        {/* <h4>Create User:</h4> */}
        {/* <p>Create a new user with few details</p> */}
          <ul>
            <li>Select Category Name</li>
            <li>Displays the Category List</li>
          </ul>                 
      </div>
    </div>

    </div>
    <Separator/>

    {/* New Section */}
    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Create Sub Category:</h2>
          <h4>Creating a new sub-category according to category selected and enters the description for the newly added sub-category.</h4>
            <ul>
              <li>Select Category Name</li>
              <li>Add New Sub-Category</li>
              <li>Description</li>
            </ul>
      </div>
        <div className='col-sm-6 mb-5'>
            <img src={Images1} className="imgs-right" alt="Search the User" title="Search the User" style={{float: "right"}}/>
        </div>
    </div>
    </div>

    </div>
    </section>
    </>
  )
}
