import React from 'react';
import Images from '../Images/matrix.PNG';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function Matrix() {
  return (
    <>
    <Watermark/>

    <section className='about' id="about">
        <title>Matrix Analysis</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Matrix Analysis</h2>
            <p>
                The ultimate app to raise, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
        <img src={Images} className="imgs-left" alt="Matrix Analysis" title="Matrix Analysis"/>
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Matrix Analysis:</h2>
        <p>Matrix shows the Escalation level of Today's, Yesterday's and overall status of Complaints which were solved and which are pending as per the hierarchy level of individuals</p>
        {/* <h4>Track by Ticket Number:</h4> */}
          <ul>
            <li>Today Promoted</li>
            <li>Today Solved</li>
            <li>Total Active Till Date</li>
            <li>Total Till Date</li>
            <li>Total Solved Till Date</li>
            <li>Yesterday Promoted</li>
            <li>Yesterday Solved</li>
          </ul>
      </div>
    </div>

    </div>

    </div>
    </section>
    </>
  )
}
