import React from 'react';
import '../CSS/styles.css';

export default function Support() {
  return (
    <>
    <section>
        <div className='container'>
        <title>Support</title>
        <div className="title">
            <h2>Support</h2>
            <p></p>
      </div>
        </div>
    </section>
    </>
  )
}
