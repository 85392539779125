// import logo from './Images/iimlogos.png';
import { HashRouter,Routes,Route} from 'react-router-dom';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import About from './Components/About';
import Features from './Components/Features';
import Screens from './Components/Screens';
import Reviews from './Components/Reviews';
import Stories from './Components/Stories';
import Privacy from './Components/Privacy';
import Works from './Components/Works';
import './App.css';
import Download from './Components/Download';
import FAQ from './Components/FAQ';
import Admin from './Help/Admin';
import ScrollToTop from './ScrollToTop';
import Footer from './Components/Footer';
import Contact from './Components/Contact';
import Support from './Components/Support';
import Dashboard from './Help/Dashboard';
import ComplainantDashboard from './Help/ComplainantDashboard';
import TechnicianDashboard from './Help/TechnicianDashboard';
import RaiseComplaint from './Help/RaiseComplaint';
import SearchComplaint from './Help/SearchComplaint';
import RaiseComplaintonbehalf from './Help/RaiseComplaintonbehalf';
import TrackComplaint from './Help/TrackComplaint';
import ComplaintOperations from './Help/ComplaintOperations';
import TechnicianBoard from './Help/TechnicianBoard';
import LiveFeed from './Help/LiveFeed';
import UserManagement from './Help/UserManagement';
import AddBulkUser from './Help/AddBulkUser';
import UserApproval from './Help/UserApproval';
import Roles from './Help/Roles';
import Matrix from './Help/Matrix';
import ManageLevel from './Help/ManageLevel';
import DesignFlow from './Help/DesignFlow';
import Category from './Help/Category';
import SubCategory from './Help/SubCategory';
import Department from './Help/Department';
import Designation from './Help/Designation';
import Report from './Help/Report';
import IVR from './Help/IVR';
import SendNotifications from './Help/SendNotifications';
import ReviewNotifications from './Help/ReviewNotifications';
import Maintenance from './Help/Maintenance';
import Help from './Components/Help';
import Complainant from './Help/Complainant';
import Technician from './Help/Technician';
import Close from './Help/Close';
import Downloads from './Components/Downloads';
import AboutCMS from './Components/AboutCMS';
import IVRNew from './Help/IVRNew';

function App() {
  return (
    <>
    <HashRouter>
      <Navbar/>
      {/* <Home/> */}
      {/* <About/>
      <Features/>
      <Works/>
      <Screens/>
      <Reviews/>
      <Stories/>
      <Privacy/> */}

    <div className=''>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/About' element={<About/>}/>
        <Route path='/Features' element={<Features/>}/>
        <Route path='/Works' element={<Works/>}/>
        <Route path='/Screens' element={<Screens/>}/>
        <Route path='/Reviews' element={<Reviews/>}/>
        <Route path='/Stories' element={<Stories/>}/>
        <Route path='/Privacy' element={<Privacy/>}/>
        <Route path='/Contact' element={<Contact/>}/>
        <Route path='/FAQ' element={<FAQ/>}/>
        <Route path='/Support' element={<Support/>}/>
        <Route path='/Download' element={<Download/>}/>
        <Route path='/Dashboard' element={<Dashboard/>}/>
        <Route path='/ComplainantDashboard' element={<ComplainantDashboard/>}/>
        <Route path='/TechnicianDashboard' element={<TechnicianDashboard/>}/>
        <Route path='/raisecomplaint' element={<RaiseComplaint/>}/>
        <Route path='/SearchComplaint' element={<SearchComplaint/>}/>
        <Route path='/RaiseComplaintonbehalf' element={<RaiseComplaintonbehalf/>}/>
        <Route path='/TrackComplaint' element={<TrackComplaint/>}/>
        <Route path='/ComplaintOperations' element={<ComplaintOperations/>}/>
        <Route path='/TechnicianBoard' element={<TechnicianBoard/>}/>
        <Route path='/LiveFeed' element={<LiveFeed/>}/>
        <Route path='/IVR' element={<IVR/>}/>
        <Route path='/UserManagement' element={<UserManagement/>}/>
        <Route path='/AddBulkUser' element={<AddBulkUser/>}/>
        <Route path='/UserApproval' element={<UserApproval/>}/>
        <Route path='/Roles' element={<Roles/>}/>
        <Route path='/Matrix' element={<Matrix/>}/>
        <Route path='/ManageLevel' element={<ManageLevel/>}/>
        <Route path='/DesignFlow' element={<DesignFlow/>}/>
        <Route path='/Category' element={<Category/>}/>
        <Route path='/SubCategory' element={<SubCategory/>}/>
        <Route path='/Department' element={<Department/>}/>
        <Route path='/Designation' element={<Designation/>}/>
        <Route path='/SendNotifications' element={<SendNotifications/>}/>
        <Route path='/ReviewNotifications' element={<ReviewNotifications/>}/>
        <Route path='/Report' element={<Report/>}/>
        <Route path='/Maintenance' element={<Maintenance/>}/>
        <Route path='/Help' element={<Help/>}/>
        <Route path='/Admin' element={<Admin/>}/>
        <Route path='/Complainant' element={<Complainant/>}/>
        <Route path='/Technician' element={<Technician/>}/>
        <Route path='/Close' element={<Close/>}/>
        <Route path='/Downloads' element={<Downloads/>}/>
        <Route path='/AboutCMS' element={<AboutCMS/>}/>
        <Route path='/IVRNo' element={<IVRNew/>}/>
      </Routes>
    </div>
    </HashRouter>
      <Footer/>
    <ScrollToTop/>
  </>
  );
}

export default App;
