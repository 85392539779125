import React from 'react';
// import ImagesIVR from '../Images/ivrgif.gif';
// import ImagesCall from '../Images/call1.png';
import ImagesInfo from '../Images/ivrinfo.png'
import Watermark from './Watermark';
import '../CSS/about.css';

export default function IVRNew() {
  return (
    <>
    <Watermark/>
    <section className='about' id="about">
        <title>IVR</title>
    {/* <div className='container mt-3'> */}
        {/* <div className='title'>
            <h2>IVR</h2> */}
            {/* <p>
                CMS-IIM Indore, the ultimate app to Search, and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p> */}
        {/* </div> */}

    <div className='container'>
    <div className='row'>
      {/* <div className='col-sm-6 mb-5'>
          <img src={ImagesIVR} className="imgs-leftivr" alt="IVR" title="IVR"/>
      </div>
      <div className='col-sm-6'>
          <h2 style={{fontWeight:"800"}}>IVR:</h2>
            <p>Complainant can logged the complaints, just one click away:</p>
              <ul style={{lineHeight:"35px"}}>
                <li>Dial 
                  <a href='tel: +91-91091-26108'> 
                    <img src={ImagesCall} alt="Calling" title="Calling" style={{height:"50px", width:"50px"}}/> +91-91091-26108
                  </a> 
                  from your registered mobile number
                </li>
                <li>
                  Select the language, Press 1 for Hindi, Press 2 for English
                </li>
                <li>
                  Please download CMS-IIM Indore Mobile Application or visit www.cmsiimi.com to know more about filed complaint, current status, reschedule technician visit and many more services
                </li>
                <li>
                  Select the Category of the Complaint
                </li>
                <li>
                  After Selection of the Category, proceed to select the Sub-Category of the complaint.
                </li>
                <li>
                  User will get 2 minutes to describe the issue of the complaint in details.
                </li>
                <li>
                  After the completion of the process, user will notify through the SMS, Email and Notifiation as per registered detials.
                </li>
                <li>
                  To know more, about the action on the complaints, Visit 
                  <a href="www.cmsiimi.com">
                    www.cmsiimi.com
                  </a> for more details with your registered mobile number
                </li>
              </ul>
      </div> */}
          <img src={ImagesInfo} className="imgs" alt="IVR" title="IVR" style={{width:"100%"}}/>
    </div>
    </div>
    {/* </div> */}
    </section>
    </>
  )
}
