import React from 'react';
import Images from '../Images/category.PNG';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function Category() {
  return (
    <>
    <Watermark/>

    <section className='about' id="about">
        <title>Category</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Category</h2>
            <p>
                The ultimate app to raise, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
        <img src={Images} className="imgs-left" alt="Category" title="Category"/>
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Add Category:</h2>
        {/* <h4>Add Escalation Level:</h4> */}
        <p>A new category can be added through the Add Category Section, where two fields are mandatory i.e. Category Name and Description of the category</p>
        <ul>
          <li>Category Name</li>
          <li>Description</li>
        </ul>
        {/* <h4 className='mt-5'>Escalation Level List:</h4> */}
        {/* <p>Manage-Level displays the list of Names and Code of the Hierarchy level of the Escalation</p>*/}
      </div>
    </div>

    </div>
    </div>
    </section>
    </>
  )
}
