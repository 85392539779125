import { FaAngleUp } from 'react-icons/fa';
import React, { useState, useEffect } from "react";
// import {CircleArrow as ScrollUpButton} from "react-scroll-up-button";
import './index.css';

const ScrollToTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className="back">
        <div className="top-to-btm">
            {" "}
            {showTopBtn && (
                <FaAngleUp className="icon-position icon-style" onClick={goToTop}/>
            )}{" "}
        </div>
        </div>
    );
};
export default ScrollToTop;