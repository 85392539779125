import React from 'react'
import "../style.css"

export default function Separator() {
  return (
    <div className="cmseparator container">
      <div class="row">
      <div class="col-bars">
        <div class="col-tabs" style={{backgroundColor:"#e5ab09"}}></div>
        <div class="col-tabs" style={{backgroundColor:"#bc7201"}}></div>
        <div class="col-tabs" style={{backgroundColor:"#302175"}}></div>
        <div class="col-tabs" style={{backgroundColor:"#bc7201"}}></div>
        <div class="col-tabs" style={{backgroundColor:"#e5ab09"}}></div>
      </div>
      </div>
    </div>
  )
}
