import React from 'react';
import Images from '../Images/complainants.png';
import Images1 from '../Images/raise.png';
import Images2 from '../Images/search.png';
import Images3 from '../Images/track.png';
import Images4 from '../Images/technicians.png';
import Images5 from '../Images/close.png';
import '../CSS/about.css';

export default function About() {
  return (
    <>
    <section className='about' id="about">
        <title>About</title>
    <div className='container mt-5'>
        <div className='title'>
            <h2>About Campus Management System</h2>
            <p>
                The ultimate app to raise, search and track all the complaints.<br/>
                With just one click you can access any feature across the app.
            </p>
        </div>
        <div className='row'>
        <div className='col-sm-6'>
            <img src={Images} className="about-img" alt="Complainant Portal" title="Complainant Portal"/>
        </div>
        <div className='col-sm-6 mt-3'>
            <h3>Complainant Portal</h3>
            <p>Complainant can raise complaint through Web, App, and IVR. Through this portal complainant can resolve their complaint regarding Air Conditioner, Audio Visual, Civil, Electrical, Lift, Telephone, Welding and other. Complainant can access to following features-</p>
            <div className='row'>
            <div className='col-sm-6'>
            <div className="about-box">
            <div className="about-box-inner">
                <img src={Images1} alt="Raise Complaint" title="Raise Complaint"/>
                    <h6>Raise</h6>
            </div>
                <p>The complainant can raise complaints through Web, App, and IVR to resolve their complaint.</p>
            </div>
            </div>   
            <div className='col-sm-6'>
            <div className="about-box">
            <div className="about-box-inner">
                <img src={Images2} alt="Search Complaint" title="Search Complaint"/>
                    <h6>Search</h6>
            </div>
                <p>The complainant can search the complaint raised by them to check the status of the complaint.</p>
            </div>   
            </div>
            <div className='col-sm-6'>
            <div className="about-box">
            <div className="about-box-inner">
                <img src={Images3} alt="Track Complaint" title="Track Complaint"/>
                    <h6>Track</h6>
            </div>
                <p>The complainant can track the complaint raised by them to check the status of the complaint.</p>
            </div>                       
            </div>
        </div>
            </div>
    </div>
    <div className='row mt-3'>
    <div className='col-sm-6 mt-5'>
        <h3>Technician Portal</h3>
        <p>Technician is the one who resolves the complaint assigned by the supervisor and visit to the complainant on assigned date, time and place to solve their issue. Technicians can operate with Web, and App, and also can access to following features-</p>
    <div className='row'>
        <div className='col-sm-6 '>
        <div className="about-box">
        <div className="about-box-inner">
            <img src={Images2} alt="Search Complaint" title="Search Complaint"/>
                <h6>Search</h6>
        </div>
            <p>Technician can search the complaint to check the number complaints assigned to them.</p>
        </div>
        </div>   
        <div className='col-sm-6'>
        <div className="about-box">
        <div className="about-box-inner">
            <img src={Images3} alt="Track Complaint" title="Track Complaint"/>
                <h6>Track</h6>
        </div>
            <p>Technician can track the complaint assigned to them to check the status of the complaint.</p>
        </div>   
        </div>
        <div className='col-sm-6 '>
        <div className="about-box">
        <div className="about-box-inner">
            <img src={Images5} alt="Close Complaint" title="Close Complaint"/>
                <h6>Close</h6>
        </div>
            <p>Once the issue is resolved; technician can close the complaint by complainants concern.</p>
        </div>                       
        </div>
    </div>
    </div>
    <div className='col-sm-6 mt-5 '>
        <img src={Images4} className="about-img" alt="Technician Portal" title="Technician Portal" style={{float: "right"}}/>
    </div>
    </div>
    
    </div>
    </section>
    </>
  )
}
