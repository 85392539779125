import React from 'react';
import Images from '../Images/designation.PNG';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function Designation() {
  return (
    <>
    <Watermark/>
    <section className='about' id="about">
        <title>Designation</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Designation</h2>
            <p>
                The ultimate app to Search, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
          <img src={Images} className="imgs-right" alt="Designation" title="Designation" style={{float: "right"}}/>
      </div>
      <div className='col-sm-6'>
          <h2 style={{fontWeight:"800"}}>Add Designation:</h2>
            <p>Click on the button <span style={{fontWeight:"bold"}}>Add Designation</span>
              , admin can Enter the Designation Name</p>
              <ul>
                <li>Enter Designation Name</li>
                {/* <li>Description</li> */}
              </ul>
      </div>
    </div>
    </div>
    </div>
    </section>
    </>
  )
}
