import React from 'react';
import Images from '../Images/complaint_operations.PNG';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function ComplaintOperations() {
  return (
    <>
    <Watermark/>

    <section className='about' id="about">
        <title>Complaint Operations</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Complaint Operations</h2>
            <p>
                The ultimate app to raise, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
        <img src={Images} className="imgs-left" alt="Complaint Operations" title="Complaint Operations"/>
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Complaint Operations</h2>
        <p>A user can review the status of complaint by one of the given parameters:</p>
        <h4>Track by Ticket Number:</h4>
          <ul>
            <li>Open</li>
            <li>Assigned</li>
            <li>Re-Assigned</li>
            <li>Re-Schedule</li>
            <li>Hold</li>
            <li>Closed</li>
            <li>Withdraw</li>
          </ul>
      </div>
    </div>

    </div>

    </div>
    </section>
    </>
  )
}
