import React from 'react';
import Images from '../Images/roles.PNG';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function UserApproval() {
  return (
    <>
    <Watermark/>

    <section className='about' id="about">
        <title>Roles & Permission</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Roles & Permission</h2>
            <p>
                The ultimate app to raise, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
        <img src={Images} className="imgs-left" alt="Roles & Permission" title="Roles & Permission"/>
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Roles & Permission:</h2>
        {/* <h4>Add Escalation Level:</h4> */}
        <p>Roles & Permission of the users can be assigned by Action button and it can be modified</p>
        <ul>
          <li>Admin</li>
          <li>Supervisor</li>
          <li>Technician</li>
          <li>Complainant</li>
        </ul>
        {/* <h4 className='mt-5'>Escalation Level List:</h4> */}
        {/* <p>Manage-Level displays the list of Names and Code of the Hierarchy level of the Escalation</p>*/}
      </div>
    </div>

    </div>
    </div>
    </section>
    </>
  )
}
