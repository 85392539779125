import React from 'react';
import Images from '../Images/complainanta.png';
import Images1 from '../Images/categorys.PNG';
import Images2 from '../Images/date.png';
import Images3 from '../Images/upload.png';
// import Images4 from '../Images/raise_image.PNG';
// import Images5 from '../Images/raise_newadd.PNG';
import Separator from '../Help/Separator';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function RaiseComplaint() {
  return (
    <>
    <Watermark/>

    <section className='about' id="about">
        <title>Raise Complaint</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Raise Complaint</h2>
            <p>
                The ultimate app to raise the complaints.
                {/* <br/>
                A single click can bring the exact information you are searching for. */}
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
        <img src={Images} className="imgs" alt="Raise Complaint as Individual" title="Raise Complaint as Individual" />
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Raise Complaint</h2>
        <p>A Complainant can raise a complaint as an individual, the process is as follow:</p>
        <p>Verify the Personal Details of the Complainant</p>
        <p>We advice to complainant to verify the personal details i.e.<br/> Full Name, Email, Mobile Number.</p>
      </div>
    </div>

    </div>
    <Separator/>

    {/* New Section */}
    <div className='container'>
    <div className='row mt-5'>
    <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Category & Sub-Category</h2>
          <h4>Select Category:</h4>
            <ul>
              <li>Electrical</li>
              <li>Plumbing</li>
              <li>Carpentry</li>
              <li>Many More</li>
            </ul>
          {/* <br/> */}
          <h4>Select Sub-Category:</h4>
          <p>If we select a Category i.e. Electrical and the Sub-Category will reflect according to the Category selected like light, fan, cables, solar, switch board etc.</p>
          {/* <br/> */}
          <h4>Enter the Description:</h4>
          <p>Mention the problem, in the details,</p>
          <h4>Update & Confirm the Address:</h4>
          <p>If person is on another place from the given default address, and wants to raise a complaint for another location, then there is an option to add-on new address.
          </p>          
    </div>
    <div className='col-sm-6 mb-5'>
        <img src={Images1} className="imgs" alt="Category-SubCategory" title="Category-SubCategory" style={{float: "right"}}/>
    </div>
    </div>
    </div>

    <Separator/>

    {/* New Section */}
    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
        <img src={Images2} className="imgs" alt="Preferred Date & Time" title="Preferred Date & Time"/>
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Preferred Date & Time</h2>
        <h4>Select Preferred Date:</h4>
        <p>Select the preferred date on which technician will visit for resolving complaint.</p>
        <h4>Select Time-Slot:</h4>
        <p>Time has been selected according to the preferred time selected by the user.</p>
      </div>
    </div>
    </div>

    <Separator/>

    {/* New Section */}
    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Upload Image, Verify & Submit:</h2>
        <h4>Upload the Image:</h4>
        <p>Uploading the images in only .jpeg/.png format:</p>
        <br/>
        <h4>Verify and Submit the Complaint:</h4>
        <p>Verify full details once, and then click on the <span style={{fontWeight:"bold"}}>Submit</span> button</p>
        {/* <img src={Images3} className="imgs" alt="Enter the Description" title="Enter the Description"/> */}
      </div>
      <div className='col-sm-6'>
        {/* <h2 style={{fontWeight:"800"}}>Upload the Image</h2> */}
        {/* <h4>Uploading the images in only .jpeg/.png format:</h4> */}
        <img src={Images3} className="imgs" alt="Upload Images" title="Upload Images"/>
      </div>
    </div>
    </div>

    </div>
    </section>
    </>
  )
}
