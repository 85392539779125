import React from 'react';
import Images from '../Images/search_complaint.PNG';
import Images1 from '../Images/search_status.PNG';
import Images2 from '../Images/search_category.PNG';
import Images3 from '../Images/search_escalation.PNG';
import Images4 from '../Images/search_interval.PNG';
import Separator from '../Help/Separator';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function SearchComplaint() {
  return (
    <>
    <Watermark/>

    <section className='about' id="about">
        <title>Search Complaint</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Search Complaint</h2>
            <p>
                The ultimate app to Search all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
        <img src={Images} className="imgs-left" alt="Search Complaint" title="Search Complaint"/>
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Search Complaint</h2>
        <p>A user can search the status of complaint by one of the given parameters:</p>
        <h4>Search by Ticket Number:</h4>
        <p>By entering the ticket number and click on the Search button, a table will display for the entered ticket number.</p>
        <h4>Search by Mobile Number</h4>
        <p>Enter the registered mobile number and click on the Search button, a table will display for the entered ticket number.</p>
      </div>
    </div>

    </div>
    <Separator/>

    {/* New Section */}
    <div className='container'>
    <div className='row mt-5'>
    <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Search by Status</h2>
          <h4>A user can search the status of complaint by one of the given parameters</h4>
            <ul>
              <li>Open</li>
              <li>Assigned</li>
              <li>Re-Assigned</li>
              <li>Re-Schedule</li>
              <li>Hold</li>
              <li>Closed</li>
              <li>Withdraw</li>
            </ul>
    </div>
    <div className='col-sm-6 mb-5'>
        <img src={Images1} className="imgs-right" alt="Status" title="Status" style={{float: "right"}}/>
    </div>
    </div>
    </div>

    <Separator/>

    {/* New Section */}
    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
          <img src={Images2} className="imgs-right" alt="Category-SubCategory" title="Category-SubCategory" style={{float: "right"}}/>
      </div>
      <div className='col-sm-6'>
          <h2 style={{fontWeight:"800"}}>Search by Category & Sub-Category</h2>
            <h4>Select Category:</h4>
              <ul>
                <li>Electrical</li>
                <li>Plumbing</li>
                <li>Carpentry</li>
                <li>Many More</li>
              </ul>
            <h4>Select Sub-Category:</h4>
            <p>If we select a Category i.e. Electrical the Sub-Category will reflect according to the Category selected like light, fan, cables, solar, switch board etc.</p>
      </div>
    </div>
    </div>

    <Separator/>

    {/* New Section */}
    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Search by Technician / Escalation</h2>
          <h4>Search by Technician:</h4>
            <p>Select the Technician Name and search for the complaint log to the associated technician.</p>
          <h4>Search by Escalation:</h4>
            <p>Select a Escalation level, it means to notify and assign the complaint to higher level according to the hierarchy.</p>
            <ul>
              <li>Engineers</li>
              <li>Officer</li>
              <li>Executive Engineer</li>
              <li>Many more..</li>
            </ul>
      </div>
      <div className='col-sm-6 mb-5'>
          <img src={Images3} className="imgs-right" alt="Technician" title="Technician" style={{float: "right"}}/>
      </div>
    </div>
    </div>

    <Separator/>

     {/* New Section */}
     <div className='container'>
     <div className='row mt-5'>
      <div className='col-sm-6'>
        <img src={Images4} className="imgs-right" alt="Time Interval" title="Time Interval"/>
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Search by Time Interval</h2>
        <h4>Search by Time Interval:</h4>
        <p>Search the complaint by time of interval between the dates, in which the user wants to retrieve the report for days, weeks or months.</p>
        <p>Search through any option, a list of complaints (table) has been generated. To view overall status of any complaint, just click on Ticket Number</p>
      </div>
    </div>
    </div>

    </div>
    </section>
    </>
  )
}
