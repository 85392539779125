import React from 'react';
import '../CSS/styles.css';

export default function Contact() {
  return (
    <>
    <section className='section'>
        <div className='container' style={{width:"100vw",height: "67vh"}}>
        <title>
          Contact Info
        </title>
        <div className="title">
            <h2>
              Contact Information
            </h2>
        {/* <p>For more information about these rights, please contact us.</p> */}
        </div>
        {/* <h2 style={{fontWeight:"800"}}>Contact Us</h2> */}
        <p>Don't hesitate to contact us if you have any questions.</p>
          <table style={{lineHeight:"30px"}}>
            {/* <tr>
              <th>Email:</th>
              <td>info@amstechinc.com</td>
            </tr> */}
            <tr style={{verticalAlign:"Top"}}>
              <th>Phone Number:</th>
              <td>+91-731-2439777</td>
            </tr>
            <tr>
              <th>Link:</th>
              <td>www.cmsiimi.com</td>
            </tr>
            <tr  style={{verticalAlign:"Top"}}>
              <th>Address:</th>
                <td>Indian Institute of Management Indore <br/>
                  Prabandh Shikhar, Rau-Pithampur Road <br/>
                  Indore - 453556, Madhya Pradesh, India
                </td>
            </tr>
          </table>
          <div><br/></div>
        </div>
    </section>
    </>
  )
}
