import React from 'react';
import Images from '../Images/closed.png';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function Close() {
  return (
    <>
    <Watermark/>
    <section className='about' id="about">
        <title>Close Complaint</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Close Complaint</h2>
            <p>
                The ultimate app to Search, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
          <img src={Images} className="imgs" alt="Report" title="Report"/>
      </div>
      <div className='col-sm-6'>
          <h2 style={{fontWeight:"800"}}>Close Complaint:</h2>
            <p>Technician will close the complaint after resolving it successfully by uploading the jobcard.</p>
              {/* <ul>
                <li>Custom (Select the date range)</li>
                <li>Escalation (Select the date range)</li>
                <li>Category (Select the Category Name and date range)</li>
                <li>Technician (Select the Technician Name and date range)</li>
                <li>Download (Download the status of all the complaints)</li>
              </ul> */}
      </div>
    </div>
    </div>
    </div>
    </section>
    </>
  )
}
