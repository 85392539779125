import React from 'react';
import Images from '../Images/bulk_user.PNG';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function AddBulkUser() {
  return (
    <>
    <Watermark/>
    <section className='about' id="about">
        <title>Add Bulk User</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Add Bulk User</h2>
            <p>
                The ultimate app to Search, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
          <img src={Images} className="imgs-right" alt="Add Bulk User" title="Add Bulk User" style={{float: "right"}}/>
      </div>
      <div className='col-sm-6'>
          <h2 style={{fontWeight:"800"}}>Upload Bulk User:</h2>
            <p>Admin can generate the bulk user by only importing the data in the format of Excel (.xlsx, .xls)</p>
              <ul>
                <li>Employee Number</li>
                <li>First Name</li>
                <li>Last Name</li>
                <li>Department</li>
                <li>Designation</li>
                <li>Mobile Number</li>
                <li>Email</li>
                {/* <li>Door, Street & Landmark</li> */}
                <li>Roles</li>
                <li>Category</li>
              </ul>
      </div>
    </div>
    </div>
    </div>
    </section>
    </>
  )
}
