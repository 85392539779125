import React from 'react'
import { Link } from 'react-router-dom';
import Images from '../Images/iimlogos.png';
// import '../CSS/styles.css';
// import '../CSS/navbar.css';
// import Home from './Home';
import '../style.css';
import jwt from 'jwt-decode' // import dependency

function checkAuthentication()
  {
    if(localStorage.getItem('cms-login-user-token') == null || localStorage.getItem('cms-login-user-token') === undefined) {
      window.location.href = "https://www.cmsiimi.com/app/#/signin-v2";
      // window.location.href = "https://www.exammerapp.com/cms/app/#/signin-v2";
    } else {
      const token = localStorage.getItem('cms-login-user-token')
      const user = jwt(token);

      let url;
      let roleId = user.activeRole.roleId;
      switch (roleId) {
        case 1:
            url = '/component/dashboard';
            break;
        case 2:
            url = '/component/dashboard';
            break;
        case 3:
            url = '/component/dashboard';
            break;
        case 5:
            url = '/component/dashboard/complainant';
            break;
        case 4:
            url = '/component/dashboard/technician';
            break;
        default: this.router.navigateByUrl('/signin-v2')
            return;
    }
    window.location.href = "https://www.cmsiimi.com/app/#"+url;
    // window.location.href = "https://www.exammerapp.com/cms/app/#"+url;
    }
  }

export default function Navbar() 
{
  return (
  <section>
    <div className="container">
  <nav className="navbar navbar-expand-lg navbar-light bg-lights">
    <h1 className="navbar-brand">
      <Link to="/">
        <img src={Images} className="imgs-p" alt="logo"/>
      </Link>
    
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
    </h1>
  {/* <div className="container-fluid" style={{background: "#fbfaf6"}}> */}
    {/* <h1 className="navbar-brand" href="/Home" style={{fontSize:"30px", fontStyle:"italic"}}>
      <strong>Complaint Management System</strong>
    </h1> */}
    
    <div className="collapse navbar-collapse sectionbar bar" id="navbarSupportedContent">
      {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0"> */}
      <ul className="navbar-nav mb-2 mb-lg-0">
      {/* <li className="nav-item">
            <Link className="nav-links" to='/Home'>Home</Link>
        </li> */}

        <li className="nav-items">
            <Link className="nav-links" to='/'>Home</Link>
        </li> 

        <li className="nav-items">
            <Link className="nav-links" to='/About'>About</Link>
        </li> 
        
        <li className="nav-items">
            <Link className="nav-links" to='/Features'>Features</Link>
        </li> 

        <li className="nav-items">
            <Link className="nav-links" to='/Help'>How to Use</Link>
        </li> 

        <li className="nav-items">
            <Link className="nav-links" to='/Works'>How It Works</Link>
        </li> 

        <li className="nav-items">
            <Link className="nav-links" to='/Screens'>Mobile App</Link>
        </li> 

        {/* <li className="nav-items">
            <Link className="nav-links" to='/Reviews'>Reviews</Link>
        </li>  */}

        {/* <li className="nav-items">
            <Link className="nav-links" to='/Stories'>Stories</Link>
        </li>  */}
        
        {/* <li className="nav-items">
          <Link className="nav-links" to='/Downloads'>Download</Link>
        </li> */}

        <li className="nav-items">
          <button className='btn' onClick={checkAuthentication}>Login</button>
          {/* <a className="nav-links" href='https://www.cmsiimi.com/app/#/signin-v2' onClick={checkAuthentication()} style={{color:"white"}}>Login</a> */}
          {/* <a className="nav-links" href='http://192.168.1.38:4200/#/auth/signin-v2' style={{color:"white"}}>Login</a> */}
          {/* <a className="nav-links" href='https://cmsiimi.com/app/#/auth/signin-v2' style={{color:"white"}}>Login</a> */}
          {/* <Link className="nav-links" to='/app/#/auth/signin-v2' style={{color:"white"}}>Login</Link> */}
        </li>
      </ul>
      
    </div>
  {/* </div> */}
</nav>
</div>
  </section>
  
  )
}
