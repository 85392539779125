import React from 'react';
import '../CSS/styles.css';
import Accordion from 'react-bootstrap/Accordion';

export default function FAQ() {
  return (
    <>
    <section>
        <div className='container'>
        {/* <div className='container' style={{height: "120vh",marginTop:"30px"}}> */}
        <title>
          FAQ's
        </title>
        <div className="title">
            <h2>
              Frequently Asked Questions
            </h2>
        </div>
        
        <div className='container mt-5'>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Can I access portal after registering successfully?</Accordion.Header>
              <Accordion.Body>
              -	No, you cannot access without user approval from the authorized person.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>How to raise complaint?</Accordion.Header>
              <Accordion.Body>
              -	Click on raise complaint and select category and subcategory and fill the necessary fields required and click on submit button.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Can I reschedule the visiting date and time?</Accordion.Header>
              <Accordion.Body>
              -	Yes, you can reschedule by click on reschedule button from mobile App, and from Web go to search complaint and then go to status and change it, also concern OTP is required from assigned technician.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Can I withdraw my compliant?</Accordion.Header>
              <Accordion.Body>
              -	Yes, you can withdraw compliant by clicking on withdraw button from mobile App, and from web search your complaint and then click on delete icon.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>How to close complaint?</Accordion.Header>
              <Accordion.Body>
              -	Technician or Supervisor can close the complaint by uploading the job card and concern OTP is required from the complainant.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>How to upload job card?</Accordion.Header>
              <Accordion.Body>
              -	Technician has to collect the job card from the supervisor and has to take signature of the complainant and supervisor and upload it by taking snapshot of the job card. Supervisor can also upload the jobcard.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>How to lodge complaint through IVR?</Accordion.Header>
              <Accordion.Body>
              -	Call on the specified number given by the authorized person and choose category and subcategory and record your complaint detail.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Can technician reschedule the complaint?</Accordion.Header>
              <Accordion.Body>
              -	NO, only complainant and supervisor can reschedule the complaint.
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="8">
              <Accordion.Header>Who can give feedback?</Accordion.Header>
              <Accordion.Body>
              -	Technician and complainant can give feedback to each other after closing the complaint.
              </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item eventKey="8">
              <Accordion.Header>Can I delete my account?</Accordion.Header>
              <Accordion.Body>
              -	No, you cannot delete your account. User's account won't be deleted it will only be deactivated, you can contact to authorized person to deactivate your account.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        </div>
    </section>
    </>
  )
}
