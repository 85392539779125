import React from 'react';
import Images from '../Images/about.png';
import '../CSS/styles.css';

export default function Stories() {
  return (
    <>
    <section className='sections container margins'>
        <div className="row">
        <title>Stories</title>
            <h1 className='h1s'>About Our App</h1>
            {/* <h1 className='h1s' style={{marginLeft:"20px"}}>Raise Complaint as an Individual:</h1> */}
            <div className="col-lg-6 col-sm-12 col-md-6 col-12">
                <img src={Images} className="imgs-about" alt="raise-verify"/>
            </div>
            <div className="col-lg-6 col-md-6 contents">
                <h2 className='h2head'>
                An Admin can raise a complaint as an Individual, the process is as follow:
                </h2>
                <h3 className='h3style'>Verify the Personal Details of the Complainer:</h3>
                <p className="ptype">We advice to complainer to verify the personal details i.e. Full Name, Email,
                Contact Number, Address.
                </p>
            </div>
        </div>
    </section>
    </>
  )
}
