import React from 'react';
import Images from '../Images/download.svg';
import Images1 from '../Images/rating.svg';
import '../CSS/styles.css';
import '../CSS/download.css';

export default function Download() {
  return (
    <section className="download" id="download">
        <div className="container">
        <title>
            Download
        </title>
            <div className="row">
                <div className="col-sm-6">
                    <div className="download-content">
                        <h3>Download Our App Now!</h3>
                        <p>Click on the direct download links to install the app into your android and iOS devices and explore all the features of CMS</p>
                        <p>Resolve all your complaints with the CMS app and track every status of your complaint with just one click..</p>
                        <a className="app-store-btn btn-google mt-4 ml-1 mr-1" target="#" href="https://play.google.com/store/apps/details?id=com.amstech.cms.iimi" role="button">
                            <span className="app-store-btn-subtitle">Get It On</span>
                            <span className="app-store-btn-title">Google Play</span>
                        </a>
                        <a className="app-store-btn btn-apple mt-4 ml-1 mr-1" target="#" href="https://apps.apple.com/in/app/cms-iim-indore/id1643595535" role="button">
                            <span className="app-store-btn-subtitle">Available on the</span>
                            <span className="app-store-btn-title">App Store</span>
                        </a>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="row app-detail">
                        <div className="col-sm-6 text-center">
                            <div className="user-info">
                                <img src={Images} alt="App Installed" title="App Installed"/>
                                <h3>20+</h3>
                                <p>App Installed</p>
                            </div>
                        </div>
                        
                        <div className="col-sm-6 text-center">
                            <div className="user-info">
                                <img src={Images1} alt="Positive Reviews" title="Positive Reviews"/>
                                <h3>10+</h3>
                                <p>Positive Reviews</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
