import React from 'react';
import Images from '../Images/screen/1.png';
import Images1 from '../Images/screen/2.png';
import Images2 from '../Images/screen/3.png';
import Images3 from '../Images/screen/4.png';
import Images4 from '../Images/screen/5.png';
import Images5 from '../Images/screen/6.png';
import Images6 from '../Images/screen/7.png';
import Images7 from '../Images/screen/8.png';
import Images8 from '../Images/screen/9.png';
import Images9 from '../Images/screen/10.png';
import Images10 from '../Images/screen/11.png';
import Images11 from '../Images/screen/12.png';
import Images12 from '../Images/screen/13.png';
import Images13 from '../Images/screen/14.png';
import Images14 from '../Images/screen/15.png';
import Images15 from '../Images/screen/16.png';
import Images16 from '../Images/screen/17.png';
import Images17 from '../Images/screen/18.png';
import Images18 from '../Images/screen/19.png';
// import Images19 from '../Images/screen/20.png';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
// import '../CSS/styles.css';  
import '../CSS/screens.css';


const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 3,

        }
    },
};

export default function Screens()
{
    return(        
    <>
    <section className="screen" id="screens">
    <div className="container mt-5">
    <title>Screens</title>
        <div className="title">
            <h2>The Outline Of Our App</h2>
            <p>Screenshots of the App view, to get a better idea of unique features.<br/>
                Explore all the information at one place.
            </p>
        </div>
            <div className='container-fluid' >   
                <div className="centered_mobile">
                <div className="screen-slider owl-carousel owl-loaded owl-drag">
                {/* <div className="owl-stage-outer"> */}
                    {/* <OwlCarousel items={3} margin={1} autoplay ={true}> */}
                    <OwlCarousel items={3} className="owl-theme" loop  nav  margin={3}  autoplay ={true} {...options}>
                    {/* <OwlCarousel items={1} className="owl-theme" loop  nav  margin={3}  autoplay ={true} style={{width:"30%", marginRight:"50%", marginLeft:"35%"}}> */}
                            
                            <div className="item">
                                <img src={Images} alt="Welcome Screen"/>
                                <div className='review'>
                                    <p>
                                        Welcome Screen of CMS
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images1} alt="Mobile Number Screen"/>
                                <div className='review'>
                                    <p>
                                        Enter the Registered Mobile Number
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images2} alt="Personal Details"/>
                                <div className='review'>
                                    <p>
                                        Personal Details
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images3} alt="Residential Details"/>
                                <div className='review'>
                                    <p>
                                        Residential Details
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images4} alt="Mobile No"/>
                                <div className='review'>
                                    <p>
                                        Mobile Number to Verify
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images5} alt="OTP to Verify"/>
                                <div className='review'>
                                    <p>
                                        Enter OTP to Verify
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images6} alt="Email Address"/>
                                <div className='review'>
                                    <p>
                                        Enter Email-id (Optional) and Click Register
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images7} alt="Registered"/>
                                <div className='review'>
                                    <p>
                                        Thank you for registering with us and wait for Approval from Admin
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images8} alt="Otp to verify"/>
                                <div className='review'>
                                    <p>
                                        Try to login through OTP
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images9} alt="Complainant Dashboard"/>
                                <div className='review'>
                                    <p>
                                        Complainant Dashboard to Raise, Search, & Track the complaints
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images10}  alt="Raise New Complaint"/>
                                <div className='review'>
                                    <p>
                                        Raise a New Complaint and fill the personal details
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images11}  alt="Select Date & Time"/>
                                <div className='review'>
                                    <p>
                                        Select Preferrable Date & Time
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images12}  alt="Complaint Images"/>
                                <div className='review'>
                                    <p>
                                        Upload the images of the complaint
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images13}  alt="Preview the Complaint"/>
                                <div className='review'>
                                    <p>
                                        Preview the Complaint and submit it
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images14}  alt="Complaint Id"/>
                                <div className='review'>
                                    <p>
                                        Complaint Id will be notify through Notifications
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images15}  alt="Complainant Dashboard with Complaints"/>
                                <div className='review'>
                                    <p>
                                        Dashboard showcase all the complaints logged by the complainant
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images16}  alt="Preview of Complaint"/>
                                <div className='review'>
                                    <p>
                                        Preview of Complaint
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images17}  alt="Message"/>
                                <div className='review'>
                                    <p>
                                        Complainant & Technician can Message simultaneously
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={Images18}  alt="Action on Complaint"/>
                                <div className='review'>
                                    <p>
                                        Track the Action on Complaints
                                    </p>
                                </div>
                            </div>
                           
                        </OwlCarousel>
                    </div>
                </div>
                </div>
        </div>
    
    </section>
    </>
    )
}