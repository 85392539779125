import React from 'react';
import Images from '../Images/review.png';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function ReviewNotifications() {
  return (
    <>
    <Watermark/>
    <section className='about' id="about">
        <title>Review Notifications</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>Review Notifications</h2>
            <p>
                The ultimate app to Search, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
          <img src={Images} className="imgs-right" alt="Review Notification" title="Review Notification" style={{float: "right"}}/>
      </div>
      <div className='col-sm-6'>
          <h2 style={{fontWeight:"800"}}>Review Notifications:</h2>
            <p>Admin / Supervisor can review the sent notifications which were sent to staff, students and department</p>
              {/* <ul>
                <li>Custom (Select the date range)</li>
                <li>Escalation (Select the date range)</li>
                <li>Category (Select the Category Name and date range)</li>
                <li>Technician (Select the Technician Name and date range)</li>
                <li>Download (Download the status of all the complaints)</li>
              </ul> */}
      </div>
    </div>
    </div>
    </div>
    </section>
    </>
  )
}
