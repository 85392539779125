import React from 'react';
import '../CSS/styles.css';

export default function Privacy() {
  return (
    <>
    <section className='sections'>
        <title>Privacy Policy</title>
      <div className="container">
      <div className="title">
            <h2>Privacy Policy</h2>
            <p></p>
      </div>
      <div class="rn-gradient-circle"></div>
      <div class="rn-gradient-circle theme-pink"></div>
        <p> <span style={{fontWeight: "800"}}>Amstech Incorporation Pvt. Ltd. </span>is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by CMS-IIM Indore.</p>
        <p>This Privacy Policy applies to our website, our mobile application. By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.</p>
        
        <h2 style={{fontWeight: "800"}}>Definitions and key terms</h2>
        <p>To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictly defined as:
        </p><ul>
          <li>Cookie: A small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, and remember information about you such as your language preference or login information.</li>
          <li>Company: When this policy mentions it refers to Amstech Incorporation Pvt. Ltd that is responsible for your information under this Privacy Policy.</li>
          <li>Country: Owner of Amstech Incorporation Pvt. Ltd. is of Indore. </li>
          <li>Customer: Customer refers to the organization or person that signs up to use the CMS-IIM Indore service to manage the relationship with service users.</li>
          <li>Device: IVR and any internet connected device such as a phone, tablet and computer can be used to visit CMS-IIM Indore and use the services.</li>
          <li>Personnel: Those individuals who are employed by CMS-IIM Indore or are under contract to perform a service.</li>
          <li>Personal Data: any information that directly, indirectly, or in connection with other information including a personal identification number allows for the identification.</li>
          </ul>

        <h2 style={{fontWeight: "800"}}>What Information Do We Collect?</h2>
        <p>We collect information from you when you visit our website/app, register on our site.</p>
        
        <ul><li>Name / Username</li><li>Mobile Numbers</li><li>Email Address</li><li>Designation</li><li>Department</li><li>Address</li>
          </ul>
          <p>We also collect information from mobile devices for a better user experience, although these features are completely optional:</p><ul><li>Camera (Pictures): Granting camera permission allows the user to upload any picture straight from the website/app, you can safely deny camera permissions for this website/app.</li><li>Photo Gallery (Pictures): Granting photo gallery access allows the user to upload any picture from their photo gallery, you can safely deny photo gallery access for this website/app.</li>
          </ul>
        
        <h2 style={{fontWeight: "800"}}>How Do We Use The Information We Collect?</h2>
        <p>Any of the information we collect from you may be used in one of the following ways:
        </p><ul>
          <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)</li>
          <li>To send periodic emails</li>
        </ul>
        <p></p>

        <h2 style={{fontWeight: "800"}}>How Do We Use Your Email Address?</h2>
        <p>By submitting your email address on this website/app, you agree to receive emails from us. We only send emails to people who have authorized us to contact them, either directly, or through a third party. We do not send unsolicited commercial emails, because we hate spam as much as you do.
        </p>

        <h2 style={{fontWeight: "800"}}>How Do We Protect Your Information?</h2>
        <p>
          We implement a variety of security measures to maintain the safety of your personal information when you submit, or access your personal information. We offer the use of a secure server. All supplied sensitive information is transmitted via Secure Socket Layer (SSL) technology is never kept on file. We cannot, however, ensure or warrant the absolute security of any information you transmit to Amstech Incorporation Pvt. Ltd or guarantee that your information on the Service may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.
          <br/>
          We respect the privacy of our users. We collect only information that is related to resolving the complaint like mobile number, mail id and address as the project build is an in-house project so the information which is needed to resolve the complaint is all in concern with the organization.<br/>
          The user's account won't be deleted it will only be deactivated so as to check or revise the history of the complaints resolved by the assigned technicians.
        </p>

        <h2 style={{fontWeight: "800"}}>Can I update or correct my information?</h2>
        <p>The rights you have to request updates or corrections to the information CMS-IIM Indore collects and updates according to the users consent. Personnel may update or correct their information.</p>
        <p>If you are an end user and wish to update, delete, or receive any information we have about you, you may do so by contacting the organization.</p>
      
        <p>
          By using CMS-IIM Indore or contacting Amstech Incorporation Pvt. Ltd. you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, you should not engage with our website/app, or use our services. Continued use of the website/app, direct engagement with us, or following the posting of changes to this Privacy Policy that do not significantly affect the use or disclosure of your personal information will mean that you accept those changes.
        </p>
        
        <h2 style={{fontWeight: "800"}}>Your Consent</h2>
        <p>We've updated our Privacy Policy to provide you with complete transparency into what is being set when you visit our site and how it's being used. By using our website/app, registering an account, you hereby consent to our Privacy Policy and agree to its terms.
        </p>

        <h2 style={{fontWeight: "800"}}>Local Storage</h2>
        <p>Local Storage sometimes known as DOM storage provides web apps with methods and protocols for storing client-side data. Web storage supports persistent data storage, similar to cookies but with a greatly enhanced capacity and no information stored in the HTTP request header.</p>
      
        <p>For more information about these rights, please contact us.</p>
        <h2 style={{fontWeight: "800"}}>Contact Us</h2>
        <p>Don't hesitate to contact us if you have any questions.</p>
        <ul><li>Via Email:  info@amstechinc.com</li><li>Via Phone Number:  +91-731-2439777</li><li>Via this Link:  https://www.cmsiimi.com</li><li>Via this Address: Indian Institute of Management Indore, Prabandh Shikhar, Rau-Pithampur Road, Indore - 453556, Madhya Pradesh, India</li>
        </ul><script data-cfasync="false" src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
        </div>
    </section>
    </>
  )
}
