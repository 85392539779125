import React from 'react';
import Images from '../Images/create_user.PNG';
import Images1 from '../Images/search_user.PNG';
import Separator from '../Help/Separator';
import Watermark from '../Help/Watermark';
import '../CSS/about.css';

export default function UserManagement() {
  return (
    <>
    <Watermark/>

    <section className='about' id="about">
        <title>User Management</title>
    <div className='container mt-3'>
        <div className='title'>
            <h2>User Management</h2>
            <p>
                The ultimate app to Search, search and track all the complaints.<br/>
                A single click can bring the exact information you are searching for.
            </p>
        </div>

    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6 mb-5'>
        <img src={Images} className="imgs-left" alt="User Management" title="User Management"/>
      </div>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>User Management</h2>
        <p>Create search or modify the users through the user management,</p>
        <h4>Create User:</h4>
        <p>Create a new user with few details</p>
          <div className='columns'>
            <li>Employee Id</li>
            <li>First Name</li>
            <li>Last Name</li>
            <li>Mobile Number</li>
            <li>Alternate Mobile Number</li>
            <li>Email (Optional)</li>
          </div>
          <div className='columns'>
            <li>Department</li>
            <li>Designation</li>
            <li>Select Role as Admin, Technician</li>
            <li>House Number</li>
            <li>Street</li>
            <li>Landmark</li>
          </div>
        
      </div>
    </div>

    </div>
    <Separator/>

    {/* New Section */}
    <div className='container'>
    <div className='row mt-5'>
      <div className='col-sm-6'>
        <h2 style={{fontWeight:"800"}}>Search the User:</h2>
          <h4>Admin can search, modify the status of users by one of the given parameters,</h4>
            <ul>
              <li>Employee Id</li>
              <li>Mobile Number</li>
              <li>Email Id</li>
              <li>Department</li>
              <li>Select Role</li>
              <li>Keyword (Only Characters)</li>
            </ul>
      </div>
        <div className='col-sm-6 mb-5'>
            <img src={Images1} className="imgs-right" alt="Search the User" title="Search the User" style={{float: "right"}}/>
        </div>
    </div>
    </div>

    </div>
    </section>
    </>
  )
}
