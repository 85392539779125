import React from 'react';
import '../CSS/styles.css';
import '../CSS/features.css';
import Images from '../Images/dashboard.png';
import Images0 from '../Images/raise.png';
import Images1 from '../Images/search.png';
import Images2 from '../Images/track.png';
// import Images3 from '../Images/live.png';
// import Images4 from '../Images/technician.png';
// import Images5 from '../Images/escalation.png';
// import Images6 from '../Images/design.png';
// import Images7 from '../Images/notifications.png';
import { Link } from 'react-router-dom';

export default function Complainant() {
  return (
    <>
    <section className="features mt-3" id="features">
    <div className="container">
    <title>Complainant Help</title>
        <div className="title">
            <h2>Complainant</h2>
            <p>Discover all the useful features related to complainant<br/>
                Every single piece of information is at your fingertip.
            </p>
        </div>
        <div className="row justify-content-center mt-5" style={{paddingBottom:"40px"}}>
            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images} className="img-fluid" alt="Dashboard" title="Dashboard"/>
                <div className="card-body nav-items mt-5">
                    <Link className="nav-links" to="/ComplainantDashboard" style={{fontWeight:"800"}}>Dashboard</Link>
                        {/* <h3>Dashboard</h3> */}
                        <p className='mt-3'>On dashboard, you can check the complaniant details of the active open complaints.</p>
                </div>
                </div>
            </div>
           
            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images0} className="img-fluid" alt="Complaint" title="Complaint"/>
                    <div className="card-body nav-items mt-5">
                        {/* <h3>Complaint</h3> */}
                        <Link className="nav-links" to="/RaiseComplaint" style={{fontWeight:"800"}}>Raise Complaint</Link>
                        <p className='mt-3'>The complainant can raise complaints through Web, App, and IVR to resolve their complaint.</p>
                    </div>
                </div>
            </div>

            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images1} className="img-fluid" alt="Complaint" title="Complaint"/>
                    <div className="card-body nav-items mt-5">
                        {/* <h3>Complaint</h3> */}
                            <Link className="nav-links" to="/SearchComplaint" style={{fontWeight:"800"}}>Search Complaint</Link>
                        <p className='mt-3'>The complainant can search the complaint raised by them to check the status of the complaint.</p>
                    </div>
                </div>
            </div>

            <div className="col-md-5 col-lg-3 col-xl-3">
                <div className="card">
                    <img src={Images2} className="img-fluid" alt="Complaint" title="Complaint"/>
                    <div className="card-body nav-items mt-5">
                        {/* <h3>Complaint</h3> */}
                            <Link className="nav-links" to="/TrackComplaint" style={{fontWeight:"800"}}>Track Complaint</Link>
                        <p className='mt-3'>The complainant can track the complaint raised by them to check the status of the complaint</p>
                    </div>
                </div>
            </div>

            <div className='mb-5'></div>
        </div>
    </div>
    </section>
    </>
  )
}
