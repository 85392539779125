import React from 'react';
import ScrollToTop from '../ScrollToTop';
// import About from './About';
import Features from './Features';
import Works from './Works';
import Reviews from './Reviews';
import Screens from './Screens';
import Download from './Download';
// import Footer from './Footer';
import '../CSS/styles.css';
import '../CSS/home.css';
import AboutCMS from './AboutCMS';
// import Images from '../Images/hero.png';
// import Images1 from '../Images/hero1.png';
// import '../style.css';
// import Privacy from './Privacy';
// import Stories from './Stories';

export default function Home() {
  return (
    <>
    <section className="hero" id="home">
        <title>Welcome to IIM-CMS</title>
        <div className="position-relative wrapper" style={{position: "absolute",height: "95vh"}}>
        {/* <div className="position-relative wrapper" style={{position: "absolute",height: "85vh"}}> */}
        {/* <picture>
            <img src={Images} alt="Desktop"/>
            <img src={Images1} alt="mobile"/>
            <source media="(min-width: 800px)" srcset="../Images/hero.png"/>
            <source media="(max-width: 799px)" srcset="../Images/hero1.png"/>
        </picture> */}
            {/* <img className="wrapper" alt="banner" style={{position: "absolute",height: "85vh"}}/> */}
            {/* <div className="row justify-content-center" style={{backgroundImage:'url("../Images/hero.png")',backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundPosition: "center"}}> */}
        {/* <div className="row justify-content-center" style={{marginLeft:"0",marginRight:"0"}}> */}
        <div className="row justify-content-center" style={{paddingTop:"10px",marginRight:"0px !important", marginLeft: "0px !important"}}>
        {/* <div className="row justify-content-center" style={{paddingTop:"35px"}}> */}
            <div className="col-xl-8 col-lg-12 col-md-12 col-sm-6 text-center">
                <div className="hero-content">
                    <h1>
                        <span className="text-uppercase">Welcome to</span><br/>CMS IIM-I
                    </h1>
                    {/* <p className="text-capitilize">No. 1 app to raise, search and track the details for their complaints.<br/>
                        Check out the ticket info, updated details and much more.</p> */}
                    <h2 style={{fontWeight:"600", marginTop: "-22px"}}>
                        Toll Free Number
                        <br/>
                        <a href="tel:+91-9109126108" style={{color:"#302175 !important"}}>
                            +91-91091-26108
                        </a>
                    </h2><br/>
                    <div className="app-buttons">
                        <a className="app-store-btn btn-google mt-3 ml-1 mr-1" target="#" href="https://play.google.com/store/apps/details?id=com.amstech.cms.iimi" role="button">
                            <span className="app-store-btn-subtitle">Get It On</span>
                            <span className="app-store-btn-title">Google Play</span>
                        </a>
                        <a className="app-store-btn btn-apple mt-3 ml-1 mr-1" target="#" href="https://apps.apple.com/in/app/cms-iim-indore/id1643595535" role="button">
                            <span className="app-store-btn-subtitle">Available on the</span>
                            <span className="app-store-btn-title">App Store</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    {/* <About/> */}
    <AboutCMS/>
    <Features/>
    <Works/>
    <Screens/>
    <Reviews/>
    {/* <Stories/> */}
    {/* <Privacy/> */}
    <Download/>
    {/* <Footer/> */}
    <ScrollToTop/>
    </>
  )
}
